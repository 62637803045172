<template>
  <section id="courses">
    <h1 id="page-title">
      <b-row class="align-items-center">
        <b-col>{{ $t('courses') }}</b-col>
        <b-col class="d-md-none d-block" cols="auto" v-if="roleId != 5">
          <button class="btn-themed" @click="addCourse">
            <PlusSvg class="svg-white svg-icon-sm"/>
          </button>
        </b-col>
      </b-row>
    </h1>
    <b-row class="row-wide">
      <b-col md="auto">
        <router-link v-if="selectedcourse" class="btn-themed btn-white d-block mb-3 d-md-none"
                     :to="roleLinkAppendix() + '/courses'">
          {{ $t('courses') }}
        </router-link>
        <div class="left-sideblock d-md-block" :class="{ 'd-none': selectedcourse }">
          <button class="btn-themed w-100 d-md-block d-none mb-24"
                  v-if="roleId != 5"
                  @click="addCourse">{{ $t('add_course') }}
          </button>
          <div class="courses-wrap">
            <div v-for="(carr, ctype) in courses" :key="'course-type-'+ctype">
              <template v-if="ctype === 'teacher'">
                <h5 class="sidebar-h">{{ $t(ctype+'_courses') }}</h5>
                <div v-for="(c,cind) in carr" :key="ctype+'-'+cind">
                  <h5 class="sidebar-h mt-4 text-capitalize" v-if="c.length">{{ c[0].instructor.name }}</h5>
                  <div v-for="cc in c" :key="`${ctype}-${cind}-${cc.id}`">
                    <CourseIcon :course="cc"
                                :ctype="ctype"
                                @click.native="courseselected(cc)"
                                :class="{ active: selectedcourse && selectedcourse.id == cc.id }"/>
                  </div>
                </div>
              </template>
              <template v-else>
                <div v-if="carr.length > 0" class="mb-4">
                  <h5 class="sidebar-h">{{ $t(ctype+'_courses') }}</h5>
                  <div v-for="(c,cind) in carr" :key="ctype+'-'+cind">
                    <CourseIcon :course="c"
                                :ctype="ctype"
                                @click.native="courseselected(c)"
                                :class="{ active: selectedcourse && selectedcourse.id == c.id }"/>
                  </div>
                </div>
              </template>
            </div>
          </div>
        </div>
      </b-col>
      <b-col md>
        <div class="gray-select-please course-constructor"
             v-if="view == 'search'">
          {{ $t('please_select_the_course') }}
        </div>
        <CourseConstructor ref="courseconstructor" v-if="view == 'course'"
                           class="course-constructor"
                           :course="selectedcourse"
                           :ctype="selectedtype"
                           @added="added"
                           :levels="levels"
                           @reload="getCourses(true)"
        />
      </b-col>
    </b-row>
    <AddCourseModal @added="added"
                    @reload="getCourses(true)"
                    :course="addingnew ? null : selectedcourse"/>
  </section>
</template>

<script>
import CourseIcon from '@/views/teasers/CourseIcon'
import CourseConstructor from '@/views/pages/instructor/courses/CourseConstructor'
import AddCourseModal from '@/views/pages/instructor/courses/AddCourseModal'
import {CoursesService} from '@/services/api.service'
import EventBus from '@/services/event-bus'
import PlusSvg from '@/assets/svg/plus.svg'
// import SearchSvg from '@/assets/svg/search.svg'

export default {
  components: {
    CourseConstructor,
    CourseIcon,
    PlusSvg,
    AddCourseModal,
    // SearchSvg
  },
  data() {
    return {
      view: 'search',
      courses: {},
      selectedcourse: null,
      selectedtype: null,
      levels: [],
      addingnew: false
    }
  },
  mounted() {
    this.getCourses(true)
    EventBus.$emit('breadcrumbs', [
      {to: '/courses', title: this.$t('courses')},
    ])
  },
  computed: {
    roleId() {
      return this.$store.state.user ? this.$store.state.user.primary_role_id : 2
    },
  },
  watch: {
    $route() {
      if (this.$route.params.id) {
        this.findSelected()
      } else {
        this.selectedcourse = null
        this.selectedtype = null
        this.view = 'search'
      }
    }
  },
  methods: {
    findSelected() {
      for (const ctype in this.courses) {
        if ({}.hasOwnProperty.call(this.courses, ctype)) {
          if(ctype === 'teacher') {
            for(const tctype in this.courses[ctype]) {
              this.courses[ctype][tctype].forEach(item => {
                if (item.id == this.$route.params.id) {
                  this.selectedcourse = item
                  this.selectedtype = ctype
                  this.view = 'course'
                }
              })
            }
          } else {
            this.courses[ctype].forEach(item => {
              if (item.id == this.$route.params.id) {
                this.selectedcourse = item
                this.selectedtype = ctype
                this.view = 'course'
              }
            })
          }

        }
      }
    },
    addCourse() {
      this.addingnew = true
      this.$bvModal.show('addcoursemodal')
    },
    added(newcourse) {
      this.courses.custom.push(newcourse)
      this.selectedcourse = newcourse
      this.view = 'course'
      this.$router.push({
        name: this.$store.state.user.primary_role_id == 4 ? 'SchoolCourses' : 'Courses',
        params: {id: newcourse.id}
      })
    },
    getCourses(initial = false) {
      CoursesService.get().then(res => {
        let d = res.data.data
        this.courses = d.courses
        console.log(this.courses)
        this.levels = d.levels
        if (initial && this.$route.params.id) {
          this.findSelected()
        }
      })
    },
    search() {

    },
    courseselected(course) {
      if ([4,5].includes(this.roleId)) {
        this.$router.push({name: 'SchoolCourses', params: {id: course.id}})
        return
      }
      this.$router.push({name: 'Courses', params: {id: course.id}})
    }
  },
  metaInfo() {
    return {
      title: this.$t('courses'),
    }
  },
  created() {
    this.$store.state.mainClass = 'pb-0'
  },
  beforeDestroy() {
    this.$store.state.mainClass = null
  }
}
</script>

<style scoped lang="scss">
.courses-wrap {
  margin-top: 32px;
}
.left-sideblock {
  position: sticky;
  top: 0;
  overflow-y: scroll;
  max-height: calc(100vh - 176px);
}
.course-constructor {
  top: 20px;
  ::v-deep > .spacecard {
    overflow-y: scroll;
    max-height: calc(100vh - 200px);
  }
  //position: sticky;
}

@media(max-width: 768px) {
  .courses-wrap {
    margin-top: 20px;
  }
  .left-sideblock {
    max-height: none;
    overflow-y: auto;
  }
}
</style>


