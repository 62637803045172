<template>
    <b-modal id="addcoursemodal" modal-class="modal-right-pane">
        <template v-slot:modal-header="{ close }">
            <h2>{{ course ? $t('edit_course') : $t('add_course') }}</h2>
            <a class="pointer position-relative modal-close" @click="close()">
                <CloseSvg/>
            </a>
        </template>
        <div>
            <h3>{{ $t('title') }}</h3>
            <InputGroup :label="$t('title')" class="default" type="text" @enter="save" v-model="form.title"/>
            <h3>{{ $t('photo') }}</h3>
            <p class="form-note">{{ $t('image_requirements') }}</p>
            <FileGroup accept="image/png,image/jpg,image/jpeg"
                       v-model="image"
                       :deletefile="true"
                       @deleteFile="form.deletefile = true"
                       fid="coursephoto"
                       :label="$t('add_photo')"/>
        </div>
        <template v-slot:modal-footer>
            <b-button v-if="course" @click="destroy" :disabled="loading" class="btn-themed btn-white"><TrashSvg/></b-button>
            <b-button class="btn-themed" :disabled="!valid || loading" @click="save">{{ $t('save') }}</b-button>
        </template>
    </b-modal>
</template>

<script>
    import CloseSvg from '@/assets/svg/close.svg'
    import InputGroup from "@/views/parts/general/form/InputGroup"
    import FileGroup from "@/views/parts/general/form/FileGroup"
    import { CoursesService } from '@/services/api.service'
    import TrashSvg from '@/assets/svg/trash2.svg'

    export default {
        components: {
            CloseSvg, InputGroup, FileGroup, TrashSvg
        },
        data() {
            return {
                form: {
                    title: "",
                },
                image: null,
                loading: false,
            }
        },
        props: {
            course: {}
        },
        watch: {
            course(val) {
                if(val) {
                    this.form = {
                        title: val.title
                    }
                    this.image = val.image
                } else {
                    this.form = {
                        title: ""
                    }
                }
            }
        },
        mounted() {
        },
        computed: {
            valid() {
                return this.form.title
            }
        },
        methods: {
            save() {
                if(this.loading) { return }
                let formData = new FormData()
                if(this.image && typeof this.image === 'object') {
                    formData.append('image', this.image)
                }
                formData = this.appendFormdata(formData, this.form)
                this.loading = true
                if(this.course) {
                    CoursesService.update(this.course.id, formData).then(res => {
                        console.log(res)
                        this.loading = false
                        this.$emit('reload')
                        this.$bvModal.hide('addcoursemodal')
                    }).catch((err) => {
                        this.errResponse(err)
                        this.loading = false
                    })
                } else {
                    CoursesService.add(formData).then(res => {
                        this.currentCourse = res.data.data
                        this.loading = false
                        this.$emit('added', this.currentCourse)
                        this.$bvModal.hide('addcoursemodal')
                    }).catch((err) => {
                        this.errResponse(err)
                        this.loading = false
                    })
                }

            },
            destroy() {
                this.$confirm(this.$t('are_you_sure_you_want_to_delete')).then(() => {
                    this.loading = true
                    CoursesService.delete(this.course.id).then(() => {
                        this.$success(this.$t('course_deleted'))
                        let name =  'Courses'
                        if(this.$store.state.user.primary_role_id == 4) {
                            name = 'SchoolCourses'
                        }
                        this.$router.push({ name: name })
                        this.loading = false
                        this.$bvModal.hide('addcoursemodal')
                        this.$emit('reload')
                    }).catch((err) => {
                        this.errResponse(err)
                        this.loading = false
                    })
                })
            },
        }
    }
</script>

<style scoped lang="scss">

</style>


