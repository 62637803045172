<template>
  <b-modal id="assigncoursemodal"
           ref="assigncoursemodal"
           @show="onShow"
           modal-class="modal-right-pane">
    <template v-slot:modal-header="{ close }">
      <h2>{{ $t('visibility_options') }}</h2>
      <a class="pointer position-relative modal-close" @click="close()">
        <CloseSvg/>
      </a>
    </template>
    <div class="pt-3">
      <div>
        <div class="search-input-wrapper mb-5">
          <SearchSvg class="position-absolute search-icon"/>
          <input class="search-form-control" v-model="q" :placeholder="$t('search')"/>
        </div>
      </div>
      <div class="checkbox form-group">
        <input type="checkbox"
               name="all"
               value="all"
               v-model="options"
               @input="visibleAllChanged"
               id="all"/>
        <label class="mb-0 text-black font-weight-bolder" for="all">{{ $t('visible_to_all') }}</label>
      </div>
      <div v-for="t in teachersSorted" :key="`teacher-${t.id}`">
        <div class="checkbox form-group">
          <input type="checkbox"
                 :name="`t-${t.id}`"
                 :value="t.id"
                 v-model="options"
                 :id="`t-${t.id}`"/>
          <label class="mb-0 text-black" :for="`t-${t.id}`">{{ t.name }}</label>
        </div>
      </div>
    </div>
    <template v-slot:modal-footer>
      <b-button class="btn-themed" @click="save" :disabled="loading">{{ $t('save') }}</b-button>
    </template>
  </b-modal>
</template>

<script>

import CloseSvg from '@/assets/svg/close.svg'
import SearchSvg from '@/assets/svg/search.svg'
import {CoursesService, TeachersService} from "../../../services/api.service"

export default {
  name: "AssignCourseModal",
  components: {
    CloseSvg,
    SearchSvg
  },
  props: {
    course: {}
  },
  data() {
    return {
      loading: false,
      teachers: [],
      options: [],
      q: '',
    }
  },
  computed: {
    teachersSorted() {
      if(!this.q) return this.teachers
      return this.teachers.filter(t => t.name.toLowerCase().includes(this.q.toLowerCase()))
    }
  },
  methods: {
    save() {
      CoursesService.assign(this.course.id, {
        options: this.options
      }).then(() => {
        this.$bvModal.hide('assigncoursemodal')
        this.$toast(this, this.$t('change_success'))
        this.$emit('reload')
      })
    },
    getTeachers() {
      TeachersService.get({}, this.page, 999, 1).then(res => {
        this.teachers = res.data.data.data
      }).catch(err => {
        console.log(err)
      })
    },
    onShow() {
      this.getTeachers()
      if(this.course.visible_options) {
        this.options = this.course.visible_options
      } else {
        this.options = []
      }
    },
    visibleAllChanged() {
      const hasAll = (this.options.filter(x => x == 'all')).length == 0 // because it is launched before value changes
      if(!hasAll) {
        this.options = []
        return
      }
      this.options = this.teachers.map(x => x.id)
      this.options.push('all')
    }
  }
}
</script>

<style scoped>
.text-black {
  color: #000 !important;
}
</style>
